import React from 'react';
import ServerApi from "../Controllers/ServerApi";
import pkg from "../../package.json"
import { Link } from 'react-router-dom';
import Footer from './Footer';

function ExpeditionMenu(props) {
    return (
        <>
            <footer id="footer" className="footer fixed-bottom opacity-1 mt-auto bg-light border-top p-0">
                <section className="container-fluid">
                    <div className="row justify-content-center align-items-center no-gutters border-bottom">
                        <div className="col-3">
                            <Link to="/expedition/separation" className={`btn btn-${props.active === "separation" && "primary"} btn-block w-100 rounded-0 py-2`}>
                                <i className="fad fa-hand-holding-box d-block mx-auto"></i>
                            </Link>
                        </div>
                        <div className="col-3">
                            <Link to="/expedition/dispatch" className={`btn btn-${props.active === "dispatch" && "primary"} btn-block w-100 rounded-0 py-2`}>
                                <i className="fad fa-pallet d-block mx-auto"></i>
                            </Link>
                        </div>
                        <div className="col-3">
                            <Link to="/expedition/truck-loading" className={`btn btn-${props.active === "truck-loading" && "primary"} btn-block w-100 rounded-0 py-2`}>
                                <i className="fad fa-truck-loading d-block mx-auto"></i>
                            </Link>
                        </div>
                    </div>
                </section>
                <Footer />
            </footer>
        </>
    );
}

export default ExpeditionMenu;