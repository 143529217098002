import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import ServerApi from '../Controllers/ServerApi';
import User from '../Controllers/User';
import ModalConfirm from '../components/ModalConfirm';

function Profile() {
    const history = useHistory();
    if (!User.Get("token")) history.push("/login");
    else { const token = User.Get("token"); }

    const ModalConfirmRef = useRef();

    const [loading, setLoading] = useState(false)

    const logout = () => {
        setLoading(true);
        User.Logout().then((res) => history.push("/login"));
    }

    useEffect(() => {

    }, [history, loading]);

    return (
        <>
            <ModalConfirm ref={ModalConfirmRef} />
            <Header title="Meu Perfil" back="/" />
            <main id="Profile" className="py-3">
                <section className="container">
                    <div className="row">
                        <p className="col-12 col-md-6">
                            <small className="d-block">Nome completo:</small>
                            <b>{User.Get("name")}</b>
                        </p>
                        <p className="col-6 col-md-3">
                            <small className="d-block">Função:</small>
                            <b>{User.Get("role") ? User.Get("role") : "Não informado"}</b>
                        </p>
                        <p className="col-6 col-md-3">
                            <small className="d-block">Nome de usuário:</small>
                            <b>{User.Get("username") && User.Get("username").toUpperCase()}</b>
                        </p>
                        <p className="col-12">
                            <small className="d-block">Token:</small>
                            <b className="d-block w-100 text-overflow">{User.Get("token")}</b>
                        </p>
                    </div>

                    <button className="btn btn-danger btn-lg rounded-pill d-block w-100 px-3 fw-bold" onClick={() => {
                        ModalConfirmRef.current.show({
                            message: `Deseja realmente sair?`,
                            onconfirm: () => logout()
                        })
                    }}>Sair do app {loading && <i className="fad fa-spinner-third fa-spin ms-2"></i>}</button>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Profile;