import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import pkg from '../../package.json';

import ServerApi from '../Controllers/ServerApi';
import User from '../Controllers/User';

import { installMenu, dropConfig } from '../pages/Setup';


function Header(props) {
    const history = useHistory();
    const [title, setTitle] = useState(props.title);
    const [subtitle, setSubtitle] = useState(props.subtitle);

    useEffect(() => {
        (!ServerApi.Get("url")) && history.push("/setup");
        (!User.Get("token")) && history.push("/login");

        document.title = title;
        if (title === undefined)
            setTitle(pkg.longName);

    }, [history, title]);

    return (
        <>
            <header id="header" className="navbar navbar-dark bg-black shadow-sm fixed-top">
                <div className="container-fluid">
                    {props.back && <Link as="button" to={props.back} className="btn btn-link text-light text-uppercase fw-bold">{props.backText ? props.backText : <i className={`fad ${props.back === "/" ? "fa-home" : "fa-arrow-left"}`}></i>}</Link>}
                    <h1 className="navbar-brand fw-bold my-0 me-auto" onClick={() => window.location.reload()}>
                        {title}
                    </h1>
                    {subtitle && <small className="d-block text-muted">{subtitle}</small>}
                </div>
            </header>
            <div className="mt-5 mb-1"></div>
        </>
    );
}

export default Header;