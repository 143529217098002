import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../components/Loading';

import ServerApi from '../Controllers/ServerApi';
import User from '../Controllers/User';
import Setup, { installMenu } from './Setup';

function Dashboard() {
    const history = useHistory();

    const [loading, setLoading] = useState(true)
    const [menu, setMenu] = useState(JSON.parse(localStorage.getItem("menu")));
    const token = (User.Get("token")) ? User.Get("token") : "edgvi10";

    useEffect(() => {
        // (!process.env.REACT_APP_SERVER && !ServerApi.Get("url")) && history.push("/setup");
        (!User.Get("token")) && history.push("/login");

        if (menu === null) {
            setMenu(JSON.parse(localStorage.getItem("menu")));
        }
    }, [history, menu]);

    const instructionsRef = useRef();
    function listen() {
        try {
            var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            var recognition = new SpeechRecognition();
            const instructions = instructionsRef.current;

            // console.log(instructions);
            instructions.classList.toggle("d-none");

            var noteContent = "";

            recognition.onstart = function () {
                instructions.innerHTML = 'Ouvindo...';
            }

            recognition.onspeechend = function () {
                // instructions.innerHTML = 'O silêncio desliga a função';
                instructions.classList.toggle("d-none");
            }

            recognition.onerror = function (event) {
                if (event.error === 'no-speech') {
                    instructions.innerHTML = 'Fala não detectada';
                };
            }
            recognition.onresult = function (event) {
                var current = event.resultIndex;

                var transcript = event.results[current][0].transcript;

                noteContent += transcript;

                var opcao = null;
                if (noteContent.toString().search("abrir"))
                    opcao = "abrir";

                const operations = ["abrir", "ir", "ver"];
                const screens = {
                    "separação": "/expedition/separation",
                    "conferência": "/expedition/dispatch",
                    "carregamento": "/expedition/truck-loading",
                    "perfil": "/profile"
                };

                Object.keys(screens).forEach((sentense, index) => {
                    // console.log(sentense, index);
                    if (noteContent.toString().search(sentense) !== -1 && opcao === "abrir")
                        history.push(screens[sentense]);
                });

                // console.log(noteContent);
            }

            recognition.start();

            console.log("listening")
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <Header title="Painel de Controle" />
            {loading && <Loading />}
            <main className="py-3">
                <section className="container-fluid">
                    <header className="row align-items-center mb-3">
                        <p className="col-6 text-primary m-0">Olá <b>{User.Get("name")}</b>.</p>
                        <div className="col-6 text-end">
                            <button onClick={() => { installMenu().then(res => setMenu(JSON.parse(localStorage.getItem("menu")))); }} className="btn btn-primary btn-sm fw-bold me-2"><i className="fad fa-download"></i></button>

                            <button onClick={() => { listen() }} className="btn btn-sm btn-primary me-2 d-none" style={{ position: "relative" }}>
                                <i className="fad fa-microphone"></i>
                                <span ref={instructionsRef} className="instructions d-none p-1 rounded" style={{ position: "absolute", top: "100%", left: "-50%", translate: "translateX(50%)", background: "rgba(0,0,0,0.4)" }}>Tooltip</span>
                            </button>

                            <Link to="/profile" className="btn btn-primary btn-sm fw-bold"><i className="fad fa-user me-2"></i> Perfil</Link>
                        </div>
                    </header>
                    <ul className="card-list list-unstyled row justify-content-center">
                        {menu !== null &&
                            menu.map((card, index) =>
                                <li key={index} className="mb-4 col-md-8">
                                    <header className="pb-0">
                                        <h3 className="h4">{card.title}</h3>
                                        <p className="m-0">{card.description}</p>
                                    </header>

                                    {card.items &&
                                        <ul className="row justify-content-start list-unstyled no-gutters">
                                            {card.items.map((item, index) =>
                                                <li key={index} className={"col mt-3"}>
                                                    <Link to={item.url === undefined ? "/" : item.url}>
                                                        <button className="btn btn-primary btn-sm btn-block px-4 w-100 nowrap" disabled={item.disabled || item.url === undefined ? true : false}>
                                                            <i className={item.icon + " d-block mx-auto my-2 h2"}></i>
                                                            {item.label}
                                                        </button>
                                                    </Link>
                                                </li>
                                            )}
                                            <div className="fade-end" />
                                        </ul>
                                    }
                                </li>
                            )
                        }
                    </ul>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Dashboard;