import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import Routes from './routes';

import Online from './components/Online';

ReactDOM.render(
  <React.StrictMode>
    <>
      <Routes /><Online />
    </>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
