export const isJson = str => {
    str = typeof str !== "string" ? JSON.stringify(str) : str;
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const trimChar = (string, charToRemove) => {
    while (string.charAt(0) === charToRemove) {
        string = string.substring(1);
    }

    while (string.charAt(string.length - 1) === charToRemove) {
        string = string.substring(0, string.length - 1);
    }

    return string;
}

export const search = (list, keyword, options) => {
    keyword = keyword.toString();
    const results = [];
    if (options === undefined) options = {};

    list.map(item => {
        if (typeof item === "object") {
            var keys = Object.keys(item);

            if ("fields" in options)
                keys = options["fields"];

            let found = false;

            keys.map(key => {
                if (item[key] !== null) {
                    var field = item[key].toString();
                    if ("ignoreCase" in options) {
                        if (field.search(keyword) !== -1)
                            found = true;
                    } else {
                        if (field.toLowerCase().search(keyword.toLowerCase()) !== -1)
                            found = true;
                    }
                }
                return true;
            });
            if (found) results.push(item);
        } else {
            if (item.toString().search(keyword) !== -1) results.push(item);
        }
        return true;
    });

    return results;
};
