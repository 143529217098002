import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import ServerApi from '../Controllers/ServerApi';
import User from '../Controllers/User';
import { trimChar } from '../utils';
import Loading from '../components/Loading';

export const dropConfig = () => {
    if (window.confirm("Deseja apagar as configurações?")) {
        ServerApi.Uninstall();
    }
}

export const installMenu = async () => {

    const baseurl = ServerApi.Get("url");
    const token = User.Get("token");

    return await axios.get(`${baseurl}/${token}/users/menu`).then(response => {
        const data = response.data;
        localStorage.setItem("menu", JSON.stringify(data.menu));

        return true;
    });
}

export const Setup = () => {
    document.title = "Configurar Servidor";

    const cancelToken = axios.CancelToken.source();

    const history = useHistory();

    const ApiURL = useRef();

    const [feedback, setFeedbackApi] = useState({});
    const [loading, setLoading] = useState(false);

    const [use_proxy, setUseProxy] = useState(false);

    const cancelRequest = () => {
        console.log("Cancelando");
        setLoading(false);

        ApiURL.current.classList.remove("is-invalid", "is-valid");

        cancelToken.cancel();
    }

    const createConfig = async event => {
        console.log("Criando configuração");
        event.preventDefault();
        setLoading(true);

        ApiURL.current.classList.remove("is-invalid", "is-valid");

        var api_url = ApiURL.current.value;

        if (api_url.indexOf("http") === -1) api_url = "http://" + api_url;
        if (use_proxy) api_url = "https://linkproxy.herokuapp.com/" + api_url;

        let installed = false;
        try {
            await axios.get(api_url, {
                cancelToken: cancelToken.token
            }).then(async response => {
                let result = response.data;

                console.log(result);

                if (result.success) {
                    ApiURL.current.classList.add("is-valid");

                    api_url = trimChar(api_url, "/");
                    let apiObject = {
                        url: api_url,
                        version: result.version
                    }

                    await ServerApi.Install(apiObject).then(() => {
                        setFeedbackApi({ success: "Versão da " + result.version });
                        history.push("/");
                    });
                } else {
                    setFeedbackApi({ invalid: "Servidor inválido." });
                    ApiURL.current.classList.add("is-invalid");
                }
            });
        } catch (e) {
            setFeedbackApi({ invalid: "Não foi possível se conectar ao servidor." });
        }

        setLoading(false);
    }

    useEffect(() => {
        let server_url = ServerApi.Get("url");

        if (server_url) {
            console.log(server_url);
            if (server_url.indexOf("https://linkproxy.herokuapp.com/") === 0) {
                setUseProxy(true);
                // setCurrentUrl()
            }

            ApiURL.current.value = server_url.replace("https://linkproxy.herokuapp.com/", "");
        }
    }, []);

    return (
        <>
            <main>
                <div className="container my-5">

                    <form method="post" onSubmit={(event) => createConfig(event)} className="row needs-validation">
                        <h1 className="fw-thick mb-5">Informe o <code className="text-primary">Caminho (URL) e a porta</code> do servidor da API.</h1>

                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input type="text" ref={ApiURL} id="ApiURL" autoCorrect="off" autoCapitalize="off" className="form-control form-control-lg" placeholder={`URL do Servidor`} validation={["required", "url"]} required />
                                <label htmlFor="ApiURL" className="control-label">URL do Servidor</label>

                                {feedback?.invalid && <div className="invalid-feedback text-center">{feedback.invalid}</div>}
                                {feedback?.success && <div className="valid-feedback text-center">{feedback.success}</div>}
                            </div>


                            <p className={`d-inline-block px-3 mb-5 ${!use_proxy ? "text-dark" : "text-success"} fw-bold`} onClick={() => setUseProxy(!use_proxy)}><i className={`${!use_proxy ? "fal fa-square" : "fad fa-check-square"} me-2`}></i> Usar Proxy</p>
                        </div>

                        <footer className="col-12 col-md-12">
                            {loading ?
                                <span className="btn btn-danger btn-block d-block w-100 py-2 px-5 rounded-pill fw-bold mx-auto" onClick={cancelRequest}>
                                    Cancelar <i className="fad fa-spinner-third fa-spin ms-2"></i>
                                </span>
                                :
                                <button type="submit" className="btn btn-primary btn-block d-block w-100 py-2 px-5 rounded-pill fw-bold mx-auto">
                                    Conectar
                                </button>
                            }
                        </footer>

                        <p className="mt-5 text-muted">Para <b>acesso externo</b> utilize o <b>DNS ou IP</b> com roteador configurado e portas liberadas.</p>
                    </form>

                </div>
            </main>
            <Footer />
        </>
    );
}

export default Setup;