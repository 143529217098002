import React, { useState, useEffect } from 'react';

function Online() {
    const [online, setOnlineStatus] = useState(false);

    const updateStatusNotification = () => {
        console.log(navigator.onLine)
        setOnlineStatus(navigator.onLine);
    }

    useEffect(() => {
        setOnlineStatus(navigator.onLine);
    }, [online]);

    window.addEventListener('online', updateStatusNotification);
    window.addEventListener('offline', updateStatusNotification);

    return (
        online === false && <small className="d-block text-center text-light bg-danger m-0 p-0 small">sem internet</small>
    );
}

export default Online;