import React from 'react';
import axios from 'axios';
import { isJson } from '../utils';

const ServerApi = {
    Get: (field) => {
        let data = localStorage.getItem("server_api");

        if (data !== null && isJson(data)) {
            data = JSON.parse(data);

            if (typeof field === "string")
                return (typeof data[field] !== undefined) ? data[field] : false;
            else
                return data;
        } else
            return false;
    },
    Install: (serverObject) => {
        return new Promise((resolve, reject) => {

            resolve(
                localStorage.setItem("server_api", JSON.stringify(serverObject))
            );
        })
    },
    Update: (serverURL) => {
        // let api_url = serverURL;
        // if (api_url.indexOf("http") === -1) api_url = "http://" + api_url;

        // let installed = false;
        // try {
        //     await axios.get(api_url, {
        //         cancelToken: cancelToken.token
        //     }).then(response => {
        //         let result = response.data;

        //         console.log(result);

        //         if (result.success) {
        //             ApiURL.current.classList.add("is-valid");

        //             api_url = trimChar(api_url, "/");
        //             let apiObject = {
        //                 url: api_url,
        //                 version: result.version
        //             }

        //             ServerApi.Install(apiObject).then(() => {
        //                 setFeedbackApi({ success: "Versão da " + result.version });
        //                 history.push("/");
        //             });
        //         } else {
        //             setFeedbackApi({ invalid: "Servidor inválido." });
        //             ApiURL.current.classList.add("is-invalid");
        //         }
        //     });
        // } catch (e) {
        //     setFeedbackApi({ invalid: "Não foi possível se conectar ao servidor." });
        //     ApiURL.current.classList.add("is-invalid");
        // }
    },
    Uninstall: () => {
        localStorage.removeItem("server_api");
        window.location.assign("/setup");
    }
}


export default ServerApi;