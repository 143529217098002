import React from 'react';
import axios from 'axios';
import { isJson } from '../utils';

import ServerApi from './ServerApi';
import { useHistory } from 'react-router-dom';

const User = {
    Get: (field) => {
        let user = localStorage.getItem("user");

        if (user !== null && isJson(user)) {
            user = JSON.parse(user);

            if (typeof field === "string")
                return (typeof user[field] !== undefined) ? user[field] : false;
            else
                return user;
        } else
            return false;
    },
    Login: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
    },
    Update: (userObject) => {
        localStorage.setItem("user", JSON.stringify(userObject));
    },
    Logout: () => {
        return new Promise(resolve => {
            try {
                axios.delete(`${ServerApi.Get("url")}/login/${User.Get("token")}`).then(response => {
                    if (response.data.success) {
                        resolve(localStorage.removeItem("user"));
                        resolve(localStorage.removeItem("menu"));

                        return true;
                    }
                    return false;
                });
            } catch (e) {
                console.log(e);
            }
        });
    }
}


export default User;