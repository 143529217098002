import React, { useEffect, useRef, useState } from 'react';
import ServerApi from "../Controllers/ServerApi";
import pkg from "../../package.json"
import ModalAlert from './ModalAlert';
import ModalConfirm from './ModalConfirm';
import { useHistory } from 'react-router-dom';

function Footer() {
    const history = useHistory();
    const [isDev, setDev] = useState(false);
    const ModalConfirmRef = useRef();

    const allStorage = () => {
        var values = [];

        for (var i = 0; i < localStorage.length; i++) {
            var key = localStorage.key(i);
            var value = localStorage.getItem(key);

            values[key] = value;
        }

        return values;
    }

    const removeStorage = (hard = false) => {
        ModalConfirmRef.current.show({
            message: "Limpar localStorage?",
            // confirmText: "Limpar",
            confirmText: 'Limpar',
            closeText: "Cancelar",
            onconfirm: () => {
                var values = [];

                const protect = ["server_api", "user"];

                for (var i = 0; i < localStorage.length; i++) {
                    var key = localStorage.key(i);
                    var value = localStorage.getItem(key);

                    // console.log(protect.indexOf(key), key, value);
                    if (protect.indexOf(key) === -1 || hard) {
                        values[key] = value;
                        localStorage.removeItem(key);
                    }
                }

                return values;
            }
        });
    }

    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            setDev(true);
        }
    }, []);

    return (
        <>
            <ModalConfirm ref={ModalConfirmRef} />
            <footer id="footer" className="footer opacity-1 mt-auto">
                <main className="container-fluid">
                    <div className="row align-items-center text-muted my-2">
                        <div className="col-6 small">
                            <b>{pkg.longName}</b>

                            {isDev && <>
                                <i className="fad fa-sync text-primary ms-4" onClick={() => window.location.reload()}></i>
                                <i className="fad fa-trash text-danger ms-4" onClick={() => removeStorage(true)}></i>
                            </>
                            }
                        </div>
                        <div className="col-6 text-end small">
                            <i className="fad fa-mobile ms-2 me-1"></i> {pkg.version}
                            {ServerApi.Get('version') && <span onClick={() => {
                                ServerApi.Update(ServerApi.Get('url'));
                                ModalConfirmRef.current.show({
                                    message: `Caminho do Servidor: <br> <b>${ServerApi.Get('url')}</b>`,
                                    confirmText: "Alterar",
                                    onconfirm: () => history.push("/setup")
                                })
                            }}> <i className="fad fa-server ms-2 me-1"></i> {ServerApi.Get('version')}</span>}
                        </div>
                    </div>
                </main>
            </footer>
        </>
    );
}

export default Footer;