import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

import ServerApi from '../Controllers/ServerApi';
import User from '../Controllers/User';
import Footer from '../components/Footer';
import Setup, { installMenu, dropConfig } from './Setup';

function Login() {
    const history = useHistory();
    if (User.Get("token")) history.push("/");

    const baseurl = process.env.REACT_APP_SERVER ?? ServerApi.Get("url");

    const InputStore = useRef();
    const InputUser = useRef();
    const InputPass = useRef();
    const ButtonSubmit = useRef();

    const [feedback, setFeedback] = useState(false);
    const [loading, setLoading] = useState(false);

    const [stores, setStores] = useState([]);

    const resetValidation = () => {
        setFeedback(null);
        ButtonSubmit.current.classList.remove("btn-warning");
        InputUser.current.classList.remove("is-valid", "is-invalid");
        InputPass.current.classList.remove("is-valid", "is-invalid");
    }

    const handleSubmit = async event => {
        event.preventDefault();

        resetValidation();
        setLoading(true);

        const store_code = InputStore.current.value;
        const username = InputUser.current.value;
        const password = InputPass.current.value;

        if (baseurl && username !== "" && password !== "") {
            await axios.post(`${baseurl}/login`, { store_code, username, password }).then(async ({ data }) => {
                setLoading(false);
                let result = data;

                if (result.success) {
                    User.Login(result.user);
                    await installMenu().then((response) => {
                        history.push("/");
                    });

                    return;
                } else {
                    setFeedback("Combinação usuário e senha inválida");

                    ButtonSubmit.current.classList.add("btn-warning");
                    ButtonSubmit.current.innerHTML = "Tente novamente";
                }
            });
        } else {
            setFeedback("Preencha os campos");

            ButtonSubmit.current.classList.add("btn-warning");
            ButtonSubmit.current.innerHTML = "Tente novamente";
        }

        setLoading(false);
    }

    async function getStores() {
        setLoading(true);
        await axios.get(`${baseurl}/stores`).then(async ({ data }) => {
            setStores(data.stores);
        }).catch(err => {
            console.log(err)
        });
        setLoading(false);
    }

    const [setup, toggleSetup] = useState(true);

    useEffect(() => {
        if (!baseurl) history.push("/setup");
        if (User.Get("token")) history.push("/");

        if (setup) {
            console.log(setup);
            if (stores.length === 0) {
                getStores();
            }
            toggleSetup(false);
        }
    });

    return (
        <>
            <div>
                <Link to="/setup" className="btn btn-link rounded-pill p-2"><i className="fad fa-cog me-2"></i></Link>
            </div>
            <main id="login" className="d-flex flex-grow-1 align-items-center">
                <section className="container">
                    <header className="text-center">
                        <img src={`${process.env.PUBLIC_URL}/assets/logotipo.png`} alt="Logotipo" className="d-block mx-auto mb-5 w-50 logotipo" />
                    </header>

                    <form onSubmit={(event) => handleSubmit(event)} className="row px-3" autoComplete="off">
                        <div className="col-12 m-0">
                            <div className="form-floating">
                                <select ref={InputStore} id="InputStore" className="form-control rounded-top border-bottom-0" required autoCorrect="off" autoCapitalize="off">
                                    <option value="">Selecione a empresa</option>
                                    {stores.map(store => {
                                        return (<option key={store.code} value={store.code}>{store.fancyname}</option>)
                                    })}
                                </select>
                                <label htmlFor="InputStore">Qual empresa?</label>
                            </div>
                        </div>
                        <div className="col-12 m-0">
                            <div className="form-floating">
                                <input ref={InputUser} type="text" id="InputUser" className="form-control rounded-0 border-bottom-0 text-lowercase" placeholder="O mesmo do retaguarda" required autoCorrect="off" autoCapitalize="off" />
                                <label htmlFor="InputUser">Nome de Usuário</label>
                            </div>
                        </div>
                        <div className="col-12 m-0">
                            <div className="form-floating">
                                <input ref={InputPass} type="password" pattern="[0-9]*" inputMode="numeric" id="InputPass" className="form-control rounded-bottom" placeholder="Senha de 6 dígitos" required />
                                <label htmlFor="InputPass">Senha</label>
                            </div>
                        </div>

                        <div className={"form-feedback col-12 mt-2 text-center " + (!feedback && " hidden")}>
                            <p className="fw-bold text-danger">{feedback}</p>
                        </div>

                        <footer className="col-12 col-md-12 text-center">
                            {loading ?
                                <span className="btn btn-primary btn-block d-block w-100 py-2 px-5 rounded-pill fw-bold mx-auto disabled" disabled>
                                    Conectando <i className="fad fa-spinner-third fa-spin ms-2"></i>
                                </span>
                                :
                                <button ref={ButtonSubmit} type="submit" className="btn btn-primary btn-block d-block w-100 py-2 px-5 rounded-pill fw-bold mx-auto">
                                    Acessar
                                </button>
                            }
                        </footer>
                    </form>

                    <p className="p-3 small" style={{ fontSize: "0.8rem", textAlign: "justify" }}>Ao utilizar o aplicativo você automaticamente estará concordando com nossos <b>termos de uso</b> e <b>política de privacidade.</b></p>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default Login;