import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import pkg from "../package.json"

import Setup from "./pages/Setup";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Separation from "./pages/expedition/Separation";
import Dispatch from "./pages/expedition/Dispatch";
import TruckLoading from "./pages/expedition/TruckLoading";
import Orders from "./pages/Orders";

const Routes = () => (
    <BrowserRouter basename={pkg.homepage}>
        <Switch>
            <Route exact path="/setup" component={Setup} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/profile" component={Profile} />

            <Route exact path="/orders" component={Orders} />

            <Route exact path="/expedition/separation" component={Separation} />
            <Route exact path="/expedition/dispatch" component={Dispatch} />
            <Route exact path="/expedition/truck-loading" component={TruckLoading} />
        </Switch>
    </BrowserRouter>
)

export default Routes;
