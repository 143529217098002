import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Link, useHistory } from 'react-router-dom';

import pkg from '../../package.json';

const ModalAlert = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState(props.message);
    const [onconfirm, setOnConfirm] = useState(props.onconfirm);

    const [confirmText, setConfirmText] = useState(props.confirmText);

    const ModalAlertContainer = useRef();
    const buttonConfirm = useRef();

    const onConfirm = () => {
        setShow(false);
        if (typeof onconfirm === "function") onconfirm();
    }

    useImperativeHandle(ref, () => ({
        show: (props) => {
            setShow(true);
            setMessage(props.message);
            setConfirmText(props.confirmText ?? "OK");

            if (typeof props.onconfirm === "function") setOnConfirm(() => props.onconfirm);

            setTimeout(() => buttonConfirm.current.focus(), 300)
        }
    }));

    useEffect(() => {
        if (show) {
            ModalAlertContainer.current.classList.add("show");
        } else {
            ModalAlertContainer.current.classList.remove("show");
        }
    }, [props, show, message]);

    return (
        <>
            <section ref={ModalAlertContainer} className={`d-flex flex-column justify-content-center modal-alert-container `}>
                <div className="modal-alert-backdrop"></div>
                <section className="modal-alert-dialog container">
                    <div className="card card-body text-center">
                        <div className="modal-alert-title" dangerouslySetInnerHTML={{
                            __html: message
                        }}></div>

                        <div className="row mt-3">
                            <div className="col"><button ref={buttonConfirm} type="button" className="btn btn-primary fw-bold w-100" autoFocus onClick={onConfirm}>{confirmText}</button></div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
});

export default ModalAlert;