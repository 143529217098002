import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { Link, useHistory } from 'react-router-dom';

import pkg from '../../package.json';

const ModalConfirm = forwardRef((props, ref) => {
    const [show, setShow] = useState(props.show ?? false);
    const [message, setMessage] = useState(props.message);
    const [onconfirm, setOnConfirm] = useState(props.onconfirm);
    const [onclose, setOnClose] = useState(props.onclose);

    const [confirmText, setConfirmText] = useState(props.confirmText ?? "Confirmar");
    const [closeText, setCloseText] = useState(props.closeText ?? "Fechar");

    const ModalAlertContainer = useRef();
    const buttonConfirm = useRef();

    const onConfirm = () => {
        setShow(false);
        if (typeof onconfirm === "function") onconfirm();
    }

    const onClose = () => {
        setShow(false);
        if (typeof onclose === "function") onclose();
    }

    useImperativeHandle(ref, () => ({
        show: (props) => {
            setMessage(props.message);
            if (props.confirmText) setConfirmText(props.confirmText);
            if (props.closeText) setCloseText(props.closeText);

            if (typeof props.onconfirm === "function") setOnConfirm(() => props.onconfirm);
            if (typeof props.onclose === "function") setOnClose(() => props.onclose);
            setShow(true);

            setTimeout(() => buttonConfirm.current.focus(), 300)
        }
    }));

    useEffect(() => {
        if (show) {
            ModalAlertContainer.current.classList.add("show");
        } else {
            ModalAlertContainer.current.classList.remove("show");
        }
    }, [props, show, message, onconfirm, onclose]);

    return (
        <>
            <section ref={ModalAlertContainer} className={`d-flex flex-column justify-content-center modal-alert-container `}>
                <div className="modal-alert-backdrop"></div>
                <section className="modal-alert-dialog container">
                    <div className="card card-body shadow">
                        <pre className="modal-alert-title" dangerouslySetInnerHTML={{
                            __html: message
                        }}></pre>

                        <div className="row mt-3">
                            <div className="col"><button className="btn btn-light fw-bold w-100" onClick={onClose} dangerouslySetInnerHTML={{ __html: closeText }}></button></div>
                            <div className="col"><button ref={buttonConfirm} className="btn btn-primary fw-bold w-100" autoFocus onClick={onConfirm} dangerouslySetInnerHTML={{ __html: confirmText }}></button></div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
});

export default ModalConfirm;