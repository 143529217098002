import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import ServerApi from '../../Controllers/ServerApi';
import User from '../../Controllers/User';
import ExpeditionMenu from '../../components/ExpeditionMenu';

import ModalAlert from '../../components/ModalAlert';
import ModalConfirm from '../../components/ModalConfirm';
import ModalPrompt from '../../components/ModalPrompt';
import axios from 'axios';
import base64url from 'base64url';
import { search } from '../../utils';
import Loading from '../../components/Loading';

function useQuery() {
    const url_queries = window.location.search.substring(1);
    const queries = {};
    for (const query of url_queries.split("&")) {
        const param = query.split("=");
        queries[param[0]] = (param[1]) ? param[1] : true;
    }

    return queries;
}

function Dispatch() {
    const get = useQuery();
    const history = useHistory();
    const date = new Date();
    let today = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, 0) + "-" + date.getDate().toString().padStart(2, 0);

    const baseurl = process.env.REACT_APP_SERVER ?? ServerApi.Get("url");
    const token = User.Get("token");

    const [use_reader, toggleBarcodeReader] = useState(true);

    const ModalAlertRef = useRef();
    const ModalConfirmRef = useRef();
    const ModalPromptRef = useRef();

    const [setup, toggleSetup] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading_product, setLoading_product] = useState(false);

    const DeliveryDateSearchInput = useRef();

    const [deliveries, setDeliveries] = useState([]);
    const [deliveries_list, setDeliveriesList] = useState([]);
    const [delivery, setDeliveryInfo] = useState(false);

    const [volume, setVolume] = useState(0);
    const [checked, setChecked] = useState(0);

    const [list_by, setListBy] = useState("orders");

    const [delivery_products, setDeliveryProducts] = useState({});
    const BarcodeItems = useRef();

    const [delivery_orders, setDeliveryOrders] = useState([]);
    const [selected_order, setSelectedOrder] = useState(null);

    const BarcodeOrderItem = useRef();

    const [dispatched_modal, toggleDispatchedModal] = useState(false);

    async function getDeliveries() {
        setLoading("Buscando entregas no servidor");

        const delivery_date_start = today;
        console.log("getDeliveries", delivery_date_start);
        setDeliveries([]);
        setDeliveriesList([]);

        await axios.get(`${baseurl}/${token}/expedition`, { params: { delivery_date_start, dispatch: true } }).then(({ data }) => {
            if (data.success) {
                setDeliveries(data.deliveries);
                setDeliveriesList(data.deliveries);
            } else {
                console.error(data);
                window.alert(data.message);
            }
        }).catch(err => {
            console.log("falha ao buscar entregas", err);
            window.alert("Falha ao buscar entregas")
        });
        setLoading(false);
    }

    async function searchDelivery() {
        const delivery_date = DeliveryDateSearchInput.current.value;

        console.log("searchDelivery", delivery_date, deliveries);
        const filtred_deliveries = search(deliveries, delivery_date, { fields: ["date"] });
        setDeliveriesList(filtred_deliveries);
    }

    async function selectDelivery(delivery_number) {
        console.log("selectDelivery", delivery_number);
        setLoading("Bunscando informações da entrega");
        await axios.get(`${baseurl}/${token}/expedition`, { params: { delivery_number, dispatch: true } }).then(({ data }) => {
            console.log(data);
            if (data.success) {
                const delivery_info = data.deliveries[0];
                setDeliveryInfo(delivery_info);

                localStorage.setItem("wdm_active_delivery_number", JSON.stringify(delivery_info.code));
            } else {
                console.error(data);
                window.alert(data.message);
            }
        });
        setLoading(false);
    }

    async function clearDeliveryInfo() {
        localStorage.removeItem("wdm_active_delivery_number");
        urlModify({});
        setDeliveryInfo(false);
        setSelectedOrder(null);
    }

    async function getDeliveryOrders() {
        const delivery_number = localStorage.getItem("wdm_active_delivery_number");
    }

    async function selectOrder(order) {
        order.listed_products = [];
        order.dispatched_products = [];

        order.products.map(product => {
            if (product.dispatched) {
                order.dispatched_products.push(product);
            } else {
                order.listed_products.push(product);
            }
            return true;
        });

        console.log(order);
        setSelectedOrder(order);
    }

    async function confirmItemHandler(event) {
        event.preventDefault();

        const barcodeInput = document.getElementById(`product-barcode`);
        var barcodeInputVal = barcodeInput.value;
        barcodeInput.classList.remove("barcode-error");

        confirmItem(barcodeInputVal);
    }
    async function confirmItem(barcodeInputVal) {
        const barcodeInput = document.getElementById(`product-barcode`);
        barcodeInput.classList.remove("barcode-error");

        if (barcodeInputVal !== "") {
            setLoading_product(true);
            var barcodes = barcodeInputVal.split(";");
            barcodes.map(async item => {
                item = item.split(",");

                var checked_amount = null;
                if (item[1]) checked_amount = parseFloat(item[1]);

                var barcode = item[0];
                barcode = barcode.padStart(13, "0");

                const search_item = search(selected_order.products, barcode);

                const product = search_item[0];
                if (product === undefined) {
                    barcodeInput.classList.add("barcode-error");
                    setTimeout(function () {
                        barcodeInput.classList.remove("barcode-error");
                    }, 2000);

                    barcodeInput.value = "";
                    barcodeInput.focus();
                } else {
                    if (product.dispatched_unit === undefined) product.dispatched_unit = 0;

                    let item_line = document.getElementById(`product-${product.barcode}`);
                    if (item_line) {
                        item_line.classList.remove("shake");
                    }

                    if (product.requested_amount > product.dispatched_unit) {
                        if (checked_amount === null) checked_amount = window.prompt("Quantidade separada:", 1);

                        console.log("verificado: ", checked_amount);

                        if (checked_amount !== null) {
                            checked_amount = parseFloat(checked_amount);

                            if (product.requested_amount < product.dispatched_unit + checked_amount) {
                                window.alert("Quantidade incorreta");
                            } else if (product.requested_amount === product.dispatched_unit + checked_amount) {
                                let index = selected_order.listed_products.indexOf(product);
                                if (index > -1) { selected_order.listed_products.splice(index, 1); }

                                product.dispatched_unit = product.dispatched_unit + checked_amount;
                                await axios.put(`${baseurl}/${token}/expedition/dispatch/check`, {
                                    order_number: selected_order.number,
                                    barcode: product.barcode
                                }).then(({ data }) => {
                                    if (data.success) {
                                        selected_order.products_dispatched = selected_order.products_dispatched + 1;
                                    }
                                });

                                barcodeInput.value = "";
                                barcodeInput.focus();

                                product.dispatched = true;

                                selected_order.dispatched_products.push(product);
                                console.log("Concluído: ", product.name, product.requested_amount, product.dispatched_unit);
                            } else {
                                let index = selected_order.listed_products.indexOf(product);
                                if (index > -1) { selected_order.listed_products.splice(index, 1); }
                                product.dispatched_unit = product.dispatched_unit + checked_amount;
                                console.log("Adicionado: ", product.name, product.requested_amount, product.dispatched_unit);

                                selected_order.listed_products.unshift(product)
                            }
                        }
                        setTimeout(() => {
                            setLoading_product(false);
                            setSelectedOrder(selected_order);
                        }, 500);
                    } else {
                        if (item_line) {
                            item_line.classList.add("shake");
                            setTimeout(function () {
                                item_line.classList.remove("shake");
                            }, 600);
                        } else {

                            barcodeInput.classList.add("barcode-success", "shake");
                            setTimeout(function () {
                                barcodeInput.classList.remove("barcode-success", "shake");
                            }, 2000);

                            barcodeInput.value = "";
                            barcodeInput.focus();
                        }
                        setTimeout(() => {
                            setLoading_product(false);
                        }, 500);
                    }

                    barcodeInput.value = "";
                }
            })
        }

        // setTimeout(() => {
        //     setLoading_product(false);
        //     setSelectedOrder(selected_order);
        // }, 500);
    }

    async function finishDispatch() {
        const password = document.getElementById("dispatch-password").value;
        const password_64 = base64url(password + User.Get("username"));

        if (User.Get("verify_code") !== password_64) {
            window.alert("Senha incorreta.");
            return false;
        }

        const payload = {
            order_number: selected_order.number,
            user_code: User.Get("cod"),
            user_name: User.Get("name"),
        }

        setLoading("Finalizando conferência");
        await axios.put(`${baseurl}/${token}/expedition/dispatch/finish`, payload).then(({ data }) => {
            if (data.success) {
                const order_in_delivery = delivery.orders.indexOf(selected_order);
                console.log(order_in_delivery);
                if (order_in_delivery !== -1) {
                    delivery.orders.splice(order_in_delivery, 1);
                    setSelectedOrder(null);
                }
            } else {
                window.alert("algum erro ocorreu.");
            }
        });
        setLoading(false);
    }

    function scrollToView(event) {
        // const barcode = document.getElementById("product-barcode");
        // if (barcode) {
        //     const top = barcode.getBoundingClientRect().top - 60;
        //     window.scroll({ top, behavior: "smooth" });
        // }
    }

    // Setup
    useEffect(() => {
        (!ServerApi.Get("url")) && history.push("/setup");
        (!User.Get("token")) && history.push("/login");

        if (setup) {
            getDeliveries(null);
            toggleSetup(false);

            if (get.delivery_number) {
                selectDelivery(get.delivery_number);
            }
        }
    });

    const urlModify = (params) => {
        console.log("parametros", new URLSearchParams(params).toString());
        history.push({ path: window.location, search: "?" + new URLSearchParams(params).toString() });
    }

    return (
        <main className="page">
            <ModalAlert ref={ModalAlertRef} />
            <ModalConfirm ref={ModalConfirmRef} />
            <ModalPrompt ref={ModalPromptRef} />

            <Header title="Conferência" subtitle="Expedição" back="/" />

            {loading ?
                <div className="d-flex flex-column align-items-center justify-content-center p-5 loading">
                    <i className="fad fa-spinner-third fa-spin fa-2x" />
                    <small className="d-block mt-5">{loading}</small>
                </div>
                :
                <section className="delivery-container bg-light">
                    {(!delivery) ?
                        <section className="deliveries-list">
                            {deliveries.length === 0
                                ?
                                <div className="container py-3">

                                    <button onClick={getDeliveries} className="btn btn-primary w-100 d-flex justify-content-between align-items-center">
                                        Carregar entregas <i className="fad fa-download" />
                                    </button>
                                </div>
                                :
                                <section className="list-container py-3">
                                    <form onSubmit={(event) => { event.preventDefault(); getDeliveries(); }} className="container bg-white shadow-sm mb-2">
                                        <div className="form-floating">
                                            <input ref={DeliveryDateSearchInput} type="date" id="delivery_search" onChange={searchDelivery} className="form-control form-control-sm" defaultValue={today ? today : ""} placeholder="data de entrega" />
                                            <label htmlFor="delivery_search">Data de entrega</label>
                                        </div>
                                        <nav className="d-flex justify-content-center m-0 p-0">
                                            <button className="btn btn-link" onClick={() => { DeliveryDateSearchInput.current.value = today; searchDelivery() }}><i className="fad fa-calendar me-2" /> Entregas Hoje</button>
                                            <button className="btn btn-link" onClick={getDeliveries}><i className="fad fa-download me-2" /> Recarregar Lista</button>
                                        </nav>
                                    </form>
                                    {(deliveries_list.length === 0) ?
                                        <div className="alert alert-warning d-flex justify-content-between align-items-center">
                                            Nenhuma entrega nesse período <i className="fad fa-exclamation-triangle" />
                                        </div>
                                        :
                                        <ul className="list-group list-group-flush">
                                            {deliveries_list.map(delivery => {
                                                // const delivery = deliveries[order];
                                                return (
                                                    <li key={delivery.code} className="list-group-item d-flex flex-row align-items-center justify-content-between" onClick={() => {
                                                        selectDelivery(delivery.code);
                                                        urlModify({ delivery_number: delivery.code });
                                                    }}>
                                                        <div className="small m-0">
                                                            <b className=" text-uppercase">{delivery.route} ({delivery.code})</b>
                                                            <small className="d-flex flex-row gap-2 align-items-center">
                                                                <span><i className="fad fa-calendar me-2" /> {delivery.date_text}</span>
                                                                <span><i className="fad fa-receipt me-2" /> {delivery.orders.length} pedidos</span>
                                                            </small>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    }
                                </section>
                            }
                        </section>
                        :
                        <section className="current-delivery">
                            <header className="container my-2 delivery-info">
                                <button className="btn btn-primary btn-sm mb-2" onClick={clearDeliveryInfo}><i className="fad fa-arrow-left me-1" /> Entregas</button>
                                <div className="card border shadow-sm mb-2">
                                    <div className="card-body">
                                        <h6 className="">
                                            <small className="d-block text-muted">Entrega {delivery.code} &nbsp;&bull;&nbsp; {delivery.total_orders - delivery.orders.length}/{delivery.total_orders} pedido(s)</small>
                                            {delivery.route === "" ? "Rota sem nome" : delivery.route}
                                        </h6>
                                        <div className="row small">
                                            <p className="col-4 mb-2">
                                                <small className="d-block text-muted">Data</small>
                                                {delivery.date_text}
                                            </p>
                                            <p className="col-8 mb-2">
                                                <small className="d-block text-muted">Transportadora</small>
                                                {delivery.transporter.name === "" ? "Sem transportadora" : delivery.transporter.name}
                                            </p>
                                            <p className="col-6 mb-2">
                                                <small className="d-block text-muted">Motorista</small>
                                                {delivery.transporter.driver ? delivery.transporter.driver.name : "Sem motorista"}
                                            </p>
                                            <p className="col-6 mb-2">
                                                <small className="d-block text-muted">Veículo</small>
                                                {delivery.transporter.vehicle ? `${delivery.transporter.vehicle.plate} ${delivery.transporter.vehicle.name}` : "Sem motorista"}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {delivery.orders.length === 0
                                    ?
                                    <section className="text-center">
                                        <p className="alert alert-info">
                                            Não há ítens para conferir.
                                        </p>

                                        <nav className="row">
                                            <div className="col-6">
                                                <Link to="/expedition/separation" className="btn btn-primary w-100"><i className="fad fa-hand-holding-box d-block"></i> Separação</Link>
                                            </div>
                                            <div className="col-6">
                                                <Link to="/expedition/truck-loading" className="btn btn-primary w-100"><i className="fad fa-truck-loading d-block"></i> Carregamento</Link>
                                            </div>
                                        </nav>
                                    </section>
                                    :
                                    <section className="delivery-orders-list pb-4">
                                        {selected_order ?
                                            <>
                                                <nav className="d-flex justify-content-between">
                                                    <button className="btn btn-sm btn-primary p-1 px-2 mb-2" onClick={() => setSelectedOrder(null)}><i className="fad fa-arrow-left me-1" /> Pedidos</button>
                                                    <button className={`btn btn-sm ${use_reader ? "btn-success" : "btn-outline-success"} p-1 px-2 mb-2`} onClick={() => { if (window.confirm(use_reader ? "Conferir sem leitor" : "Conferir com leitor")) toggleBarcodeReader(!use_reader) }}><i className="fad fa-barcode-scan" /></button>
                                                </nav>

                                                <section className="card selected-order-box">
                                                    <div className="card-body py-2">
                                                        <div className="row small">
                                                            <p className="col-8">
                                                                <small className="d-block text-muted">Cliente {selected_order.customer_code}</small>
                                                                <span className="d-block w-100">{selected_order.customer_fancyname}</span>
                                                            </p>
                                                            <div className="col-4">
                                                                <small className="d-block text-muted">Nº Pedido</small>
                                                                {selected_order.number}
                                                            </div>
                                                            <div className="col-4">
                                                                <small className="d-block text-muted">Produtos</small>
                                                                {selected_order.products_dispatched ? selected_order.products_dispatched : "0"}/{selected_order.products.length}
                                                                {selected_order.products_dispatched === selected_order.products.length && <i className="fad fa-check text-success ms-1" />}
                                                            </div>
                                                            <div className="col-6 text-end d-flex ms-auto justify-content-end align-items-end">
                                                                <small className="d-block text-muted"></small>
                                                                <button onClick={() => toggleDispatchedModal(true)} className={`btn btn-info btn-sm p-0 px-2 ${selected_order.dispatched_products.length === 0 && "disabled"}`}>
                                                                    Ver conferidos
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`custom-modal ${dispatched_modal ? "show" : ""}`}>
                                                        <div className="container py-3 pb-5 mb-3">
                                                            <div className="card">
                                                                <h4 className="card-header">Ítens conferidos</h4>

                                                                <ul className="list-group list-group-flush">
                                                                    {selected_order.dispatched_products.map(product => {
                                                                        return (
                                                                            <li key={product.barcode} className="list-group-item small">
                                                                                <div className="row">
                                                                                    <p className="col-8 fw-bold">
                                                                                        {product.name}
                                                                                    </p>
                                                                                    <p className="col-4 text-end fw-bold">
                                                                                        <span className={`badge ${product.dispatched ? "bg-success" : "bg-warning text-dark"}`}>
                                                                                            {product.dispatched ? product.requested_amount : (product.dispatched_unit ?? "0")}/{product.requested_amount} {product.unit_name}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <footer className="fixed-bottom p-2 bg-white border-top">
                                                            <button onClick={() => toggleDispatchedModal(false)} className="btn btn-primary w-100">Fechar</button>
                                                        </footer>
                                                    </div>

                                                    <form onSubmit={(event) => { confirmItemHandler(event); }} className="form-product-check p-2">
                                                        <div className="input-group">
                                                            <input type="tel" id={`product-barcode`} className="form-control border-2" placeholder="Código de Barras" onClick={scrollToView} />
                                                            {loading_product
                                                                ?
                                                                <div className="input-group-text bg-primary text-light px-3"><i className="fad fa-spinner-third fa-spin" /></div>
                                                                :
                                                                <button type="submit" className="btn btn-success btn-sm px-3"><i className="fad fa-arrow-right" /></button>
                                                            }
                                                        </div>
                                                    </form>

                                                    {selected_order.dispatched_products.length === selected_order.products.length &&
                                                        <section className="p-2">
                                                            <div className="form-floating mb-3">
                                                                <input type="password" id="dispatch-password" className="form-control" placeholder="Senha de acesso" required />
                                                                <label htmlFor="dispatch-password">Confirme sua senha</label>
                                                            </div>
                                                            <button onClick={finishDispatch} className="btn btn-success w-100">Expedir Pedido <i className="fad fa-box-check ms-2" /></button>
                                                        </section>
                                                    }

                                                    <ul className={`list-group list-group-flush mb-3`}>
                                                        {selected_order.listed_products.map(product => {
                                                            return (
                                                                <li key={product.barcode} id={`product-${product.barcode}`} className={`list-group-item ${product.dispatched ? "text-success" : ""}`} onClick={() => {
                                                                    console.log(product.barcode, product.gtin, product.requested_amount);
                                                                    if (!use_reader) {
                                                                        if (product.requested_amount < 5) confirmItem(product.barcode + ",1");
                                                                        else confirmItem(product.barcode);
                                                                    }
                                                                }}>
                                                                    <div className="row">
                                                                        <p className="col-8 fw-bold">
                                                                            {product.name}
                                                                        </p>
                                                                        <p className="col-4 text-end fw-bold">
                                                                            <span className={`badge ${product.dispatched ? "bg-success" : "bg-warning text-dark"}`}>
                                                                                {product.dispatched ? product.requested_amount : (product.dispatched_unit ?? "0")} {product.unit_name}
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </section>
                                            </>
                                            :
                                            <>
                                                <nav className="d-flex gap-2 align-items-center justify-content-stretch w-100 mb-2">
                                                    <button className={`flex-grow-1 btn btn-sm fw-bold btn-${list_by === "orders" ? "primary" : "light"}`}><i className="fad fa-receipt me-1" /> {delivery.total_orders - delivery.orders.length} pedidos</button>
                                                    <button className={`flex-grow-1 btn btn-sm fw-bold btn-${list_by === "products" ? "primary" : "light border"} disabled`}><i className="fad fa-box me-1" /> produtos</button>
                                                </nav>

                                                {list_by === "orders" &&
                                                    <ul className="list-group shadow-sm">
                                                        {delivery.orders.map(order => {
                                                            return (
                                                                <li key={order.number} className="list-group-item">
                                                                    <div className="row small">
                                                                        <p className="col-8">
                                                                            <small className="d-block text-muted">Cliente {order.customer_code}</small>
                                                                            <span className="d-block w-100">{order.customer_fancyname}</span>
                                                                        </p>
                                                                        <div className="col-4">
                                                                            <small className="d-block text-muted">Nº Pedido</small>
                                                                            {order.number}
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <small className="d-block text-muted">Produtos</small>
                                                                            {order.products_dispatched ? order.products_dispatched : "0"}/{order.products.length}
                                                                        </div>
                                                                        <div className="col-4 ms-auto d-flex align-items-end">
                                                                            {(order.products_dispatched === order.products.length)
                                                                                ? <button className={`btn btn-success btn-sm p-1 w-100 ms-auto`} onClick={() => selectOrder(order)}>Conferido <i className="fad fa-check ms-1" /></button>
                                                                                : <button className={`btn btn-warning btn-sm p-1 w-100 ms-auto`} onClick={() => selectOrder(order)}>Conferir <i className="fad fa-arrow-right ms-1" /></button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                }
                                            </>
                                        }
                                    </section>
                                }


                            </header>
                        </section>
                    }
                </section>
            }
            <Footer />
        </main>
    );
}

export default Dispatch;