import axios, { CancelToken } from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

import ServerApi from '../Controllers/ServerApi';
import User from '../Controllers/User';

import ModalAlert from '../components/ModalAlert';
import ModalConfirm from '../components/ModalConfirm';
import ModalPrompt from '../components/ModalPrompt';

function Orders() {
    const baseurl = process.env.REACT_APP_SERVER ?? ServerApi.Get("url");
    const history = useHistory();

    const [filterParam, setFilterParam] = useState({});
    const [filterValue, setFilterValue] = useState(null);

    const filterValueRef = useRef();

    const ModalAlertRef = useRef();
    const ModalConfirmRef = useRef();
    const ModalPromptRef = useRef();

    const [orders, setOrders] = useState({});
    const [selectedOrder, setSelectedOrder] = useState(null);

    const [loading, setLoading, loadingState] = useState(false);

    const token = (User.Get("token")) ? User.Get("token") : "edgvi10";

    const listOrders = async (param, value) => {
        await axios.get(`${baseurl}/${token}/expedition/orders/${param}/${value}`).then(response => {
            const result = response.data
            if (result.success) {
                console.log(result.orders);
                if (result.orders.length === 0) {
                    ModalAlertRef.current.show({
                        message: `<i class="fad fa-exclamation-triangle text-warning d-block fa-2x mb-2"></i> Nenhum pedido encontrado`,
                    });
                }

                setOrders(result.orders);
            } else {
                console.log(result);
            }
            setLoading(false);
        }).catch(err => {
            ModalAlertRef.current.show({
                message: `<i class="fad fa-exclamation-triangle text-warning d-block fa-2x mb-2"></i> Falha ao buscar Pedidos`,
            });

            console.log(err);
            setLoading(false);
        });
    }

    const filterOrders = (event) => {
        event.preventDefault();
        setLoading(true);

        let param = filterParam.value;
        let label = filterParam.label;
        let value = filterValueRef.current.value;

        if (param === undefined) {
            setFilterParam(filterOptions[0]);
            param = filterOptions[0].value;
            label = filterOptions[0].label;
        }

        setFilterValue(value);

        if (value === "" || value === null || value === undefined) {
            let message = `Informe o ${label}`;

            ModalAlertRef.current.show({
                message,
                onconfirm: () => filterValueRef.current.focus()
            });
            console.log(message);

            setLoading(false);
            return false;
        } else {
            listOrders(param, value);
        }

    }

    const choseSearchParam = (option) => {
        // console.log(option);
        setFilterParam(option);

        if (option.label === undefined) option.label = "Código";

        // localStorage.setItem("OrderFilterParam", JSON.stringify(option));
        filterValueRef.current.value = "";
        filterValueRef.current.focus();
    }

    const filterOptions = [
        { icon: "fa-truck", label: "Número da Carga", value: "delivery_number", type: "tel" },
        { icon: "fa-user", label: "Código Vendedor", value: "seller_id", type: "tel" },
        { icon: "fa-building", label: "Código Cliente", value: "customer_id", type: "tel" },
    ];

    useEffect(() => {
        (!ServerApi.Get("url")) && history.push("/setup");
        (!User.Get("token")) && history.push("/login");

    }, [history, orders]);

    const selectOrder = (order_number) => {

    };

    return (
        <>
            <ModalAlert ref={ModalAlertRef} />
            <ModalConfirm ref={ModalConfirmRef} />
            <ModalPrompt ref={ModalPromptRef} />

            <Header title="Pedidos" back="/" />
            <main id="orders" className="py-3 container">
                <form onSubmit={filterOrders} className="row">
                    <div className="col-2 pe-1">
                        <button type="button" className="btn btn-light w-100 dropdown-container shadow-sm border">
                            <i className={`fad ${filterParam.icon ?? filterOptions[0].icon}`}></i>
                            <div className="dropdown-menu shadow-sm border-light">
                                {filterOptions.map((option, index) =>
                                    <b key={option.value} onClick={() => choseSearchParam(option)} className="dropdown-item border-top border-light fw-bold py-3"><i className={`fad ${option.icon} me-3`}></i> {option.label}</b>
                                )}
                            </div>
                        </button>
                    </div>

                    <div className="col-8 px-1 mb-3">
                        <input ref={filterValueRef} type="search" pattern="[0-9]*" inputMode="numeric" className="form-control shadow-sm" placeholder={filterParam.label ?? filterOptions[0].label} defaultValue={filterValue} />
                    </div>

                    <div className="col-2 ps-1 mb-3">
                        {loading ?
                            <span className="btn btn-danger w-100 shadow-sm fw-bold"><i className="fad fa-spinner-third fa-spin"></i></span>
                            :
                            <button type="submit" className="btn btn-success h-100 w-100 shadow-sm fw-bold"><i className="fad fa-search"></i></button>
                        }
                    </div>
                </form>

                {
                    (orders.length > 0) &&
                    <>
                        <p>
                            Pedidos encontrados: {`${orders.length}`}
                            <small className="d-block">{`${filterParam.label}: ${filterValue}`}</small>
                        </p>

                        {orders.map((order, index) => {
                            return <div key={index} className={`card rounded shadow-sm mb-3 ` + (selectedOrder === order.order_number && ` bg-primary`)} onClick={() => setSelectedOrder(order.order_number)}>
                                <p className="p-2 m-0">Pedido: {`${order.order_number}`}</p>
                            </div>
                        })}
                    </>
                }
            </main>
            <Footer />
        </>
    );
}

export default Orders;