import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { Link, useHistory } from 'react-router-dom';

import pkg from '../../package.json';

const ModalPrompt = forwardRef((props, ref) => {
    const [show, setShow] = useState(props.show ?? false);
    const [message, setMessage] = useState(props.message);
    const [onconfirm, setOnConfirm] = useState(props.onconfirm);
    const [onclose, setOnClose] = useState(props.onclose);

    const [confirmText, setConfirmText] = useState(props.confirmText ?? "Enviar");
    const [closeText, setCloseText] = useState(props.closeText ?? "Cancelar");

    const [type, setType] = useState(props.type ?? "text");
    const [placeholder, setPlaceholder] = useState(props.placeholder ?? "Informe o valor");
    const [defaultValue, setDefaultValue] = useState(props.defaultValue ?? null);

    const ModalAlertContainer = useRef();
    const inputValue = useRef();

    const onConfirm = (event) => {
        event.preventDefault();
        setShow(false);
        if (typeof onconfirm === "function") onconfirm(inputValue.current.value);
        inputValue.current.value = "";
    }

    const onClose = () => {
        setShow(false);
        if (typeof onclose === "function") onclose();
    }

    useImperativeHandle(ref, () => ({
        show: (props) => {
            setMessage(props.message);
            setConfirmText(props.confirmText);
            setCloseText(props.closeText);

            setPlaceholder(props.placeholder);
            setType(props.type);
            setDefaultValue(props.defaultValue);

            if (typeof props.onconfirm === "function") setOnConfirm(() => props.onconfirm);
            if (typeof props.onclose === "function") setOnClose(() => props.onclose);
            setShow(true);

            setTimeout(() => inputValue.current.focus(), 500)
        }
    }));

    useEffect(() => {
        if (show) {
            ModalAlertContainer.current.classList.add("show");
        } else {
            ModalAlertContainer.current.classList.remove("show");
        }
    }, [props, show, message, onconfirm, onclose]);

    return (
        <>
            <section ref={ModalAlertContainer} className={`d-flex flex-column justify-content-center modal-alert-container `}>
                <div className="modal-alert-backdrop"></div>
                <section className="modal-alert-dialog container">
                    <form onSubmit={onConfirm} className="card card-body">
                        <p className="modal-alert-title" dangerouslySetInnerHTML={{
                            __html: message
                        }}></p>

                        <input ref={inputValue} type={type ?? "text"} defaultValue={defaultValue ?? null} autoFocus placeholder={placeholder ?? null} className="form-control" />

                        <div className="row mt-3">
                            <div className="col"><button type="reset" className="btn btn-light fw-bold w-100" onClick={onClose}>{closeText ?? "Fechar"}</button></div>
                            <div className="col"><button type="submit" className="btn btn-primary fw-bold w-100">{confirmText ?? "Enviar"}</button></div>
                        </div>
                    </form>
                </section>
            </section>
        </>
    );
});

export default ModalPrompt;